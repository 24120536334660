<template>
  <div class="row">
    <div class="col-md-12 col-12 d-none d-sm-block">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/" class="navClass">首頁</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ BreadTitle }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bread",
  props: {
    BreadTitle: String,
  },
};
</script>

<style>
</style>