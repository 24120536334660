import { toClipboard } from "@soerenmartius/vue3-clipboard";
export default function DoBackup(basedata) {
    const results = {};

    console.log('From Backup:', basedata);
    console.log('Record', basedata.vTargetURL == '');
    console.log('localStorage_larmBook', localStorage.getItem("larmBook"));
    console.log('localStorage_larmBookAB', localStorage.getItem("larmBookAB"));
    console.log('localStorage_Record', localStorage.getItem("record@" + basedata.vTargetURL));

    console.log('localStorage_nanBook', localStorage.getItem("nanBook"));
    console.log('localStorage_nanBookAB', localStorage.getItem("nanBookAB"));

    console.log('localStorage_moonSelected', localStorage.getItem("moonSelected"));


    let obj = {
        Record: localStorage.getItem("record@" + basedata.vTargetURL),
        larmBook: localStorage.getItem("larmBook"),
        larmBookAB: localStorage.getItem("larmBookAB"),
        nanBook: localStorage.getItem("nanBook"),
        nanBookAB: localStorage.getItem("nanBookAB"),
        moonSelected: localStorage.getItem("moonSelected"),
        Url: basedata.vTargetURL,
    };

    console.log("JSON String", JSON.stringify(obj));
    toClipboard(JSON.stringify(obj));
    alert("複製完成，請直接使用 Ctrl+V 貼上");

    return results;
}