export default function DoLoad(basedata) {
    const results = {};

    let obj = JSON.parse(basedata.jsonstrload);
    console.log(obj);

    console.log('obj.larmBook:', obj.larmBook);
    if (obj.Url.includes('NSL')) {
        console.log('南山律');
        localStorage.setItem("nanBook", obj.nanBook);
        localStorage.setItem("nanBookAB", obj.nanBookAB);
    }

    if (obj.Url.includes('GL1')) {
        console.log('廣論');
        localStorage.setItem("larmBook", obj.larmBook);
        localStorage.setItem("larmBookAB", obj.larmBookAB);
    }

    if (obj.Url.includes('lr2')) {
        console.log('全廣2');
        localStorage.setItem("moonSelected", obj.moonSelected);

    }


    basedata.vTargetURL = obj.Url;
    localStorage.setItem("record@" + obj.Url, obj.Record);

    return results;
}