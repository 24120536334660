<template>
  <div>
    <Bread BreadTitle="廣海明月"></Bread>
    <div class="row">
      <div class="col-md-6">
        <div class="input-group">
          <span class="input-group-text">全廣 II</span>
          <select v-model="basedata.selected" class="form-control" id="sel1">
            <option v-for="(n, index) in basedata.MoonData" :key="index">
              {{ n.moonID }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="input-group">
          <input
            type="button"
            value="閱讀"
            id="btnRead"
            class="btn btn-primary multi-btn btn-sm"
            @click="DoMoonRead"
          />
          <input
            type="button"
            value="播放"
            id="btnLamAdd"
            class="btn btn-primary multi-btn btn-sm"
            @click="DoMoonAdd"
          />

          <button
            class="btn btn-success btn-sm multi-btn"
            @click="BackupRecord"
            v-if="basedata.vTargetURL != undefined"
          >
            複製紀錄
          </button>
        </div>
      </div>
      <div class="col-md-6 col-sm-6 mb-1">
        <div class="input-group">
          <input
            type="text"
            v-model="basedata.jsonstrload"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-6 col-sm-6 mb-1">
        <div class="input-group">
          <button class="btn btn-success btn-sm" @click="LoadRecord">
            匯入紀錄
          </button>
        </div>
      </div>
      <div class="col-md-12 col-sm-12">
        <AudiPlay :PlayURL="basedata.vTargetURL" ref="compAPlay" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from "vue";
import AudiPlay from "../components/AudiPlay.vue";
import Bread from "../components/Bread.vue";
import { GetMoonList } from "../../api.js";
import myBackup from "@/composables/Record/Backup";
import myLoad from "@/composables/Record/Load";
export default {
  name: "Moon",
  components: {
    AudiPlay,
    Bread,
  },
  setup() {
    let errorStatus = ref("");
    const divRef = ref(AudiPlay);
    const basedata = reactive({
      selected: null,
      jsonstrload: "",
      MoonData: [],
    });

    function BackupRecord() {
      //Call Composition API :Record/Backup.js
      myBackup(basedata);
    }

    function LoadRecord() {
      //Call Composition API :Record/Load.js
      myLoad(basedata);
    }

    function DoMoonAdd() {
      //將 arrayRecordTime 陣列清空
      basedata.arrayRecordTime = [];

      let vSource = basedata.MoonData.find((x) => x.moonID == basedata.selected)
        .source;

      basedata.vTargetURL = vSource;

      divRef.value.PlayURL = vSource;
      divRef.value.methods.DoPlay(vSource);
    }

    function DoMoonRead() {
      let vUrl = basedata.MoonData.find((x) => x.moonID == basedata.selected)
        .url;

      //test
      console.log("vUrl:", vUrl);
      console.log(
        "vTartet:",
        basedata.MoonData.find((x) => x.moonID == basedata.selected)
      );
      console.log(
        "vTartet:",
        basedata.MoonData.find((x) => x.moonID == basedata.selected).url
      );

      window.open(vUrl, "_blank");
    }

    function GetData() {
      GetMoonList()
        .then(function (response) {
          basedata.MoonData = response.data;
          basedata.MoonData.sort((a, b) => (b.moonID > a.moonID ? 1 : -1));
        })
        .catch(function (error) {
          if (!error.response) {
            // network error
            errorStatus = "Error: Network Error";
          } else {
            errorStatus = error.response.data.message;
          }
        });
    }

    onMounted(() => {
      //basedata.selected = basedata.MoonData.length;
      divRef.value.PlayURL = "";

      GetData();
      var vRecordLocalStoragemoonBook = localStorage.getItem("moonSelected");

      if (vRecordLocalStoragemoonBook == null)
        vRecordLocalStoragemoonBook = basedata.MoonData.length;

      basedata.selected = vRecordLocalStoragemoonBook;
    });

    watch(
      () => basedata.selected,
      (vNew, vOld) => {
        console.log("basedata.selected-vNew", vNew);
        console.log("basedata.selected-vOld", vOld);

        //存 local storage
        localStorage.setItem("moonSelected", vNew);
      }
    );

    return {
      basedata,
      divRef,
      DoMoonAdd,
      DoMoonRead,
      GetData,
      errorStatus,
      BackupRecord,
      LoadRecord,
    };
  },
};
</script>

<style>
.form-control label {
  padding-right: 20px;
}

.multi-btn {
  margin: 0.5em 0.5em;
}
</style>