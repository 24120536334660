<template>
  <div class="row">
    <div class="col-md-8 col-12">
      <audio id="MyAudio2" controls="" class="form-control input-lg">
        <source id="MySource2" src="" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>

    <div class="col-md-4 col-sm-8">
      <div class="input-group">
        <button class="btn btn-danger btn-sm multi-btn" @click="DoRecord">
          記憶
        </button>

        <button
          v-if="playdata.arrayRecordTime.length > 0"
          class="btn btn-danger btn-sm multi-btn"
          @click="DeleteAllRecord"
        >
          全刪
        </button>
      </div>
    </div>
    <div class="col-md-12 col-sm-12">
      <ul class="list-inline text-left">
        <li
          v-for="(n, index) in playdata.arrayRecordTime"
          :key="index"
          class="list-inline-item"
        >
          <a @click="SetTime(n)" href="#">{{ ConvertTimeFormat(n) }}</a>
          <a @click="DoDelete(n)" href="#" :class="ToTextTime(n)"
            ><span style="color: red" class="fa fa-trash"> </span
          ></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { reactive, watch, ref, onMounted } from "vue";
export default {
  name: "AudiPlay",
  props: { PlayURL: String },
  setup(props) {
    const selectedURL = ref(props.PlayURL);
    const playdata = reactive({
      arrayRecordTime: [],
    });

    function GetRecordTime() {
      return playdata.arrayRecordTime;
    }

    function CookiesToPlayRecord(newUrl) {
      var vRecordLocalStorage = localStorage.getItem("record@" + newUrl);
      console.log("in watch, vRecordLocalStorage ", vRecordLocalStorage);
      playdata.arrayRecordTime = [];
      if (vRecordLocalStorage != null) {
        vRecordLocalStorage.split(",").forEach(function (vTime) {
          playdata.arrayRecordTime.push(vTime);
        });
      }
    }

    function DeleteAllRecord() {
      var vConfirm = confirm("是否確定要刪除所有已經記憶的播放時間點?");
      if (vConfirm != true) return;

      playdata.arrayRecordTime = [];

      //移除 local storage
      localStorage.removeItem("record@" + props.PlayURL);
    }

    //從 LocalStorage 抓已經存在的撥放紀錄
    watch(
      () => props.PlayURL,
      (newTitle, oldTitle) => {
        if (newTitle != "") {
          console.log("newTitle, oldTitle :>> ", newTitle, oldTitle);
          CookiesToPlayRecord(newTitle);
        }
      }
    );


  

    onMounted(() => {
      console.log("onMounted :", props.PlayURL);
      if (props.PlayURL != "") {
        CookiesToPlayRecord(props.PlayURL);
      }
    });

    return {
      playdata,
      GetRecordTime,
      selectedURL,
      CookiesToPlayRecord,
      DeleteAllRecord,
    };
  },
  mounted: function () {
    console.log("mounted", this.playdata);
    console.log("mounted props", this.props);
  },
  methods: {
    DoRecord() {
      console.log("ddX", this.PlayURL);
      var audio = document.getElementById("MyAudio2");
      var vCurrentTime = new Number(audio.currentTime);

      this.playdata.arrayRecordTime.push(vCurrentTime);

      //排序
      this.playdata.arrayRecordTime.sort(function (a, b) {
        return a - b;
      });

      //存 local storage
      localStorage.setItem(
        "record@" + this.PlayURL,
        this.playdata.arrayRecordTime
      );
    },
    DoPlay(audiodata) {
      //console.log("Test audio", this.$refs.audio);
      console.log("In DoPlay", this.GetRecordTime);

      var audio = document.getElementById("MyAudio2");
      var source = document.getElementById("MySource2");

      //如果音檔來源空白，停止進行
      if (audiodata == "") {
        alert("請先輸入音檔來源!");
        return false;
      }

      source.src = audiodata;

      audio.load();
      audio.play();

      console.log("Playing", this.playdata);
    },
    ConvertTimeFormat(vCurrentTime) {
      return this.formatSecond(vCurrentTime);
    },
    formatSecond(secs) {
      var hr = Math.floor(secs / 3600);
      var min = Math.floor((secs - hr * 3600) / 60);
      var sec = parseInt(secs - hr * 3600 - min * 60);

      while (min.length < 2) {
        min = "0" + min;
      }
      while (sec.length < 2) {
        sec = "0" + min;
      }
      if (hr) hr += ":";
      return hr + min + ":" + sec;
    },
    SetTime(vTime) {
      var audio = document.getElementById("MyAudio2");
      audio.currentTime = vTime;
    },
    ToTextTime(vTime) {
      var vStrTime = vTime.toString();
      return vStrTime.replace(/./i, "_");
    },
    DoDelete(vOneTime) {
      var vConfirm = confirm("是否確定要刪除此紀錄?");

      if (!vConfirm) return false;

      this.playdata.arrayRecordTime = this.playdata.arrayRecordTime.filter(
        (x) => x != vOneTime
      );

      //存 local storage
      localStorage.setItem(
        "record@" + this.PlayURL,
        this.playdata.arrayRecordTime
      );
    },
  },
};
</script>

<style>
.multi-btn {
  margin: 0.5em 0.5em;
}

.list-inline-item {
  padding-right: 20px;
}
.list-inline-item a {
  padding-right: 10px;
}

/* 放大垃圾桶的字體圖標*/
.fa.fa-trash {
  font-size: 20px;
}
</style>